import * as api from "./scripts/api";
import { loading } from "./scripts/loading-spinner";
import { bindKey } from "./scripts/binding";
import { showBeacon } from "./scripts/beacons";

const signinLink = document.getElementById("signin-link")!;
const signinForm = document.getElementById("signin-form")!;
const accountLink = document.getElementById("account-link")!;

const STRIPE_PAYMENT_URL = process.env["STRIPE_PAYMENT_URL"];
const STRIPE_PAYMENT_DESCRIPTION = process.env["STRIPE_PAYMENT_DESCRIPTION"];

document.addEventListener("DOMContentLoaded", async () => {
  // Get the user's session
  signinLink.querySelector("a")!.appendChild(loading);
  const user = await api.getCurrentUser();
  signinLink.querySelector("a")!.removeChild(loading);

  // If the user is logged in, hide the sign up link
  if (user) {
    // Update the user's email in the UI
    bindKey("user.email", user.email || "unknown");
    accountLink.hidden = false;
    signinLink.hidden = true;
  }

  // Set up the registration form
  if (STRIPE_PAYMENT_URL) {
    const signupForm = document.forms["register"];
    const button = signupForm.querySelector("button")!;
    signupForm.action = STRIPE_PAYMENT_URL;
    button.disabled = false;
    button.innerText = "Register Now";
    bindKey("payment.description", STRIPE_PAYMENT_DESCRIPTION || "");
  }
});

// Show the sign up form when the link is clicked
signinLink.querySelector("a")!.addEventListener("click", (e) => {
  e.preventDefault();
  signinForm.hidden = !signinForm.hidden;
});

// Handle the sign in form submission
document.forms["sign-in"].addEventListener("submit", async (e) => {
  e.preventDefault();
  e.target.querySelector("button").disabled = true;
  const email = document.querySelector<HTMLInputElement>("input#email")!.value;

  e.target.appendChild(loading);
  const { error } = await api.sendSignInLink(email);
  e.target.removeChild(loading);

  if (error) {
    alert(error.message);
    e.target.querySelector("button").disabled = false;
  } else {
    alert("Check your email for the login link!");
  }
});

setTimeout(() => {
  showBeacon("vision-help");
}, 5000);
