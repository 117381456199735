import "../styles/beacons.css";

const beaconState = JSON.parse(localStorage.getItem("beacons") || "{}");

export const showBeacon = (beacon: string) => {
  if (beaconState[beacon]) return;

  document.querySelectorAll<HTMLElement>(`[data-beacon="${beacon}"]`).forEach(
    (el) => {
      el.classList.add("active");
    },
  );
};

// Hide beacons once they've been clicked
document.querySelectorAll<HTMLElement>("[data-beacon]").forEach((el) => {
  const beacon = el.getAttribute("data-beacon")!;
  if (beaconState[beacon]) return;

  const link = el.closest("a,button");
  (link || el).addEventListener("click", () => {
    el.remove();
    beaconState[beacon] = true;
    localStorage.setItem("beacons", JSON.stringify(beaconState));
  });
});
