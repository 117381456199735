// Replace inner HTML of elements with data-ref attribute
// with the given data.
export function bindKey(
  key: string,
  data: string,
  node: Document | Element = document,
) {
  node.querySelectorAll<HTMLSpanElement>(`span[data-ref="${key}"]`)
    .forEach((el) => {
      el.innerHTML = data;
    });
}

// Replace inner HTML of elements with data-ref attribute
// with the given data.
export function bindData(
  attrs: { [key: string]: string },
  node: Document | Element = document,
) {
  Object.entries(attrs).forEach(([key, data]) => {
    bindKey(key, data, node);
  });
}
